.profile-page {
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .profile-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .profile-page-featured-area {
    color: var(--main-content-text-color);
    background-color: var(--page-color);
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    width: 1024px;
    box-shadow: 0 4px 8px var(--shadow-color);
    display: flex;
    flex-direction: column;
  }
  
  .profile-page-content{
    background-color: var(--main-content-background-color);
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    width: 96%;
    box-shadow: 0 4px 8px var(--shadow-color);
  }

  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .profile-input, .profile-textarea {
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--input-border-color);
    box-shadow: 0 2px 4px var(--shadow-color);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 1.25em;

    background-color: var(--input-background-color);
    color: var(--input-text-color);
  }
  
  .profile-input:focus, .profile-textarea:focus {
    border-color: var(--input-active-border-color);
    box-shadow: 0 2px 8px var(--shadow-color);
    outline: none;
  }
  
  .profile-textarea {
    height: 150px;
    margin: 0px;
    padding: 0px;;
  }
  
  .profile-page-form-nav {
    justify-content: left;
    gap: 10px;
    flex-direction: row;
    display: flex;
    width: 100%;
  }