.performer-home-page {
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 100%;
}

.performer-home-page-header {
  width: 100%;
}

.performer-home-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.performer-home-page-title {
  display: flex;
  margin-right: auto;
}

.performer-home-page-featured-area {
  color: var(--main-content-text-color);
  background-color: var(--main-content-background-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: 1024px;
  box-shadow: 0 4px 8px var(--shadow-color);
  display: flex;
  flex-direction: row;
}

.performer-home-page-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
}

.performers-home-profile-center{
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.performer-home-page-content-title {
  display: flex;
}

.performer-home-profile {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 10px;
}

.performer-home-profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.performer-home-profile p {
  font-size: 1em;
  margin: 5px 0;
}

.performer-home-side-navigation-buttons {
  background-color: var(--main-nav-background-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 8px var(--shadow-color);
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.performer-home-side-navigation-button {
  background-color: var(--main-nav-button-background-color);
  color: var(--main-nav-button-text-color);
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.performer-home-side-navigation-button:hover {
  background-color: var(--main-nav-button-hover-background-color);
  color: var(--main-nav-button-hover-text-color);
}

.performer-home-side-navigation-button:active {
  background-color: var(--main-nav-button-active-background-color);
  color: var(--main-nav-button-active-text-color);
}

.performer-home-profile-member {
  background-color: var(--main-content-background-color);
  display: flex;
  gap: 10px;
  align-items: center;
}

.performer-home-member-profile-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}