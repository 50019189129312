.top-page-navigation-band {
    display: flex;
    flex-direction: row;
    width: 1024px;
    background-color: var(--top-nav-background-color);
    padding: 20px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center; 
}

.top-page-navigation-band-button {
    background-color: var(--top-nav-button-background-color);
    color: var(--top-nav-button-text-color);
    border: none;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-items: center;
    display: flex;
    gap: 10px;
}

.top-page-navigation-profile-image {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

.top-page-navigation-band-button:hover {
    background-color: var(--top-nav-button-hover-background-color);
    color: var(--top-nav-button-hover-text-color);
}