.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; 
  padding: 20px;
}


.login-page-login-buttons {
    display: flex;
    flex-direction: column; /* Ensure buttons are listed vertically */
    gap: 15px;
    width: 70%;
  }

.login-page-login-button {
    border: none;
    border-radius: 30px;
    font-size: 20px;
    cursor: pointer;
    gap: 10px;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px var(--shadow-color);
    display: flex;
  }

  .login-page-login-button:hover {
    transform: translateY(-2px);
  }
  
  .login-page-login-button:active {
    transform: translateY(0);
  }
  
  .login-page-login-icon {
    font-size: 50px;
    padding: 5px 15px 0px 15px;
  }
  
  .login-page-google-login-button {
    background-color: #db4437;
    color: #fff;
  }
  
  .login-page-google-login-button:hover {
    background-color: #e57373;
    color: #fff;
  }
  
  .login-page-twitch-login-button {
    background-color: #6441a5;
    color: #ff0;
  }
  
  .login-page-twitch-login-button:hover {
    background-color: #7d5bb5;
    color: #ff0;
  }
  
  .login-page-spotify-login-button {
    background-color: #1db954;
    color: #000;
  }
  
  .login-page-spotify-login-button:hover {
    background-color: #4caf50;
    color: #000;
  }

  .login-page-dev-login-button {
    background-color: #ff74e8;
    color: #000000;
  }

  .login-page-dev-login-button:hover {
    background-color: #f84ddc;
    color: #000000;
  }