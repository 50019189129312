.add-song {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
  }
  
  .add-song h1 {
    margin-bottom: 20px;
  }
  
  .add-song form {
    background-color: var(--main-content-background-color);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px var(--shadow-color);
    width: 100%;
    max-width: 600px;
  }