.footer {
  background-color: var(--footer-background-color);
  color: var(--footer-text);
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 -2px 5px var(--shadow-color);
  z-index: 1000; /* Ensure the footer is above other content */
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.footer-left, .footer-right {
  display: flex;
  gap: 20px;
  padding: 20px
}

.footer-link {
  color: var(--footer-link-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--footer-link-hover-color);
}

.footer-icon {
  font-size: 24px;
}