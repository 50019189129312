.performers-search-page {
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
    width:100%;
}

.performers-search-page-header {
    width: 100%;
  }

.performers-search-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.performers-search-page-title{
  display:flex;
  margin-right: auto;
}

.performers-search-page-band-search-area {
  display: flex;
  flex-direction: column;
  width: 1000px;
}

.performers-search-page-content{
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.performers-search-page-content-title{
  display: flex;
}

.performers-search-page-featured-area {
  color: var(--main-content-text-color);
  background-color: var(--main-content-background-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  text-align: left;
  width: 1024px;
  box-shadow: 0 4px 8px var(--shadow-color);
  display: flex;
  flex-direction: row;
}

.performers-search-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.performers-search-input {
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--input-border-color);
  font-size: 16px;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  color: var(--input-text-color);
  background-color: var(--input-background-color);
}

.performers-search-input:focus {
  border-color: var(--input-active-border-color);
  box-shadow: 0 2px 8px var(--shadow-color);
  outline: none;
}

.my-band-item {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 98%;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--main-content-background-color);
  box-shadow: 0 4px 8px var(--shadow-color);
  text-align: center;
  text-decoration: none;
}

.my-band-item a {
  text-decoration: none;
  color: var(--main-content-text-color);
}


.band-profile-image {
  display: flex;
  width: 100px;
  height: 100px;
}

.performers-search-page-my-bands {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}