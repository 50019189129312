.home-page {
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  font-family: Arial, sans-serif;
  width:100%;
}

.home-page-header {
  width: 100%;
}

.home-page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-page-featured-area {
  color: var(--main-content-text-color);
  background-color: var(--page-color);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  text-align: left;
  width: 1024px;
  box-shadow: 0 4px 8px var(--shadow-color);
  display: flex;
  flex-direction: row;
}

.home-page-notification-area {
  background-color: var(--main-content-background-color);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  text-align: left;
  width: 1000px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.home-page-notification-area h2 {
  margin-top: 0;
  font-size: 24px;
  color: var(--text-color);
}

.home-page-notification {
  background-color: var(--main-content-card-background-color);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.home-page-notification-username {
  font-weight: bold;
  color: var(--text-primary-color);
  margin-bottom: 5px;
}

.home-page-notification-message {
  color: var(--text-secondary-color);
  font-size: 16px;
}