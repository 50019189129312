/*.modalCreateTag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--main-nav-background-color);
    color: var(--main-content-text-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--shadow-color);
    width: 400px;
  }
  
  .overlayCreateTag {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--shadow-color);
  }
*/
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input[type="text"],
  .form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .color-inputs {
    display: flex;
    gap: 20px;
  }
  
  .color-input {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .color-input label {
    margin-bottom: 5px;
  }
  
  .color-input input[type="color"] {
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
  }

  .switch-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  
  .tag-preview {
    margin-top: 20px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  
  .tag-preview-image {
    display: flex;
    max-width: 25px;
    max-height: 25px;
  }


  .image-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
  }
  
  .image-upload input[type="file"] {
    display: none;
  }
  
  .image-upload label {
    cursor: pointer;
    padding: 10px 20px;
    background-color: var(--main-content-button-background-color);
    color: var(--main-content-button-text-color);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .image-upload img {
    max-height: 25px;
    max-width: 25px;
    border-radius: 5px;
    margin-top: 10px;
  }