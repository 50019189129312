.main-navigation {
  background-color: var(--main-nav-background-color);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.search-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search-button {
  background-color: var(--main-nav-button-background-color);
  color: var(--main-nav-button-text-color);
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: var(--main-nav-button-hover-background-color);
  color: var(--main-nav-button-hover-text-color);
}

.search-button:active {
  background-color: var(--main-nav-button-active-background-color);
  color: var(--main-nav-button-active-text-color);
}

.search-icon {
  font-size: 24px;
}

.navigation-profile-image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}