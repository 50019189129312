.performer-page {
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
  }

.performer-page-wrapper {
    display: flex;
    flex-direction: row;
  }

  .performer-page-content {
    color: var(--main-content-text-color);
    background-color: var(--main-content-background-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 8px var(--shadow-color);
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: flex-start;
    gap: 10px;
    width: 1024px;
    flex-flow: wrap;
    text-align: left;
  }

  .back-arrow {
    display: flex;
    cursor: pointer;
    margin-bottom: 10px;
    color: var(--text-primary-color);
  }
  
  .back-arrow svg {
    margin-right: 5px;
  }

  .performer-content {
    display: flex;
    flex-direction: column;
    background-color: var(--main-content-background-color);
    border-radius: 20px;
    padding: 10px;
    text-align: left;
    width: 1000px;
    box-shadow: 0 4px 8px var(--shadow-color);
  }
  
  .grid-header {
    font-weight: bold;
    background-color: var(--main-content-background-color);
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-wrap: nowrap;
  }

  
  .grid-item {
    background-color: var(--main-content-background-color);
    color: var(--main-content-text-color);
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .queue-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 2px solid var(--text-primary-color);
    border-radius: 50%;
    background-color: var(--main-content-background-color);
    color: var(--text-primary-color);
    font-size: 20px;
    font-weight: bold;
    margin: 0 auto;
  }
  
  .grid-item-frame {
      background-color: var(--main-content-card-background-color);
      border-radius: 10px;
      padding: 10px;
      box-shadow: 0 2px 4px var(--shadow-color);
      min-height: 30px;
  }


/* 
Styles for the right side navigation bar
*/
  .performer-page-right-side-navigation {
    background-color: var(--main-nav-background-color);
    color: var(--text-primary-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    margin-left: 20px;
    box-shadow: 0 4px 8px var(--shadow-color);
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
  
  .performer-page-search-container {
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .performer-page-search-input {
    padding: 10px;
    padding-right: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .performer-page-search-icon {
    position: absolute;
    right: 10px;
    top: 18px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #888;
  }

  .performer-page-right-side-navigation a{
    color: var(--text-primary-color);
    font-size: large;
    font-weight: bold;
    text-decoration: none;
  }


  .tags-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 25px;
  }

  .tags-content-container {
    padding: 5px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .tag-image {
    width: 25px;
    height: 25px;
  }

