/* Logo styling */
.logo-container {
  margin-bottom: 20px;
}

.logo {
  font-size: 72px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.title-container {
  max-width: 100%;
  align-items: center; /* Vertically align items */
  justify-content: center; /* Horizontally align items */
  display: flex;
}

/*
.title {
  font-family: 'Cherry Bomb One', sans-serif;
  line-height: 2em;
  margin: 0;
  padding: 0;
  background: -webkit-linear-gradient(var(--logo-top-color), var(--logo-bottom-color));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
*/



.title_song {
  font-family: 'Titan One', sans-serif;
  line-height: 2em; /* Set line height */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
background-color: var(--logo-bottom-color);

  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.title_shake {
  font-family: 'Titan One', sans-serif;
  line-height: 2em; /* Set line height */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  background-color: var(--logo-top-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-container:hover{
  animation: shaking 0.3s linear 2;
}

@keyframes shaking {
  0%, 50%, 100% {
      transform: rotate(0deg);
  }
  20% {
      transform: rotate(-5deg);
  }
  70% {
      transform: rotate(5deg);
  }
}

.SongShakeMilkshake{
  height: 150px;
  width: 75px;
}