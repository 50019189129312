.oauth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background);
  font-family: Arial, sans-serif;
}

.oauth-page-content {
  text-align: center;
  background-color: var(--page-color);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.oauth-page h1 {
  font-size: 24px;
  color: var(--text-primary-color);
  margin-bottom: 20px;
}

.oauth-page p {
  font-size: 18px;
  color: var(--text-primary-color);
}