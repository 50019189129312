.register-band-page {
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
}

.register-band-page-header {
    width: 100%;
}

.register-band-page-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.register-band-page-title {
    display: flex;
    margin-right: auto;
}

.register-band-page-band-search-area {
    display: flex;
    flex-direction: column;
}

.register-band-page-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.register-band-page-content-title {
    display: flex;
}

.register-band-page-featured-area {
    color: var(--main-content-text-color);
    background-color: var(--main-content-background-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    width: 1024px;
    box-shadow: 0 4px 8px var(--shadow-color);
    display: flex;
    flex-direction: row;
}

.register-band-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.register-band-input {
    width: 95%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid var(--input-border-color);
    font-size: 16px;
    box-shadow: 0 2px 4px var(--shadow-color);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    color: var(--input-text-color);
    background-color: var(--input-background-color);
}

.register-band-input:focus {
    border-color: var(--input-active-border-color);
    box-shadow: 0 2px 8px var(--shadow-color);
    outline: none;
}

.register-band-form-buttons{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.register-band-page-content-description{
    color: var(--important-text-color);
    background-color: var(--important-background-color);
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    text-align: left;
    box-shadow: 0 4px 8px var(--shadow-color);
}

.checkbox-container {
    display: flex;
    align-items: center;
  }

  .checkbox-container input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid var(--shadow-color);
    border-radius: 4px;
    background-color: var(--main-content-text-color);
    cursor: pointer;
  }
   
  .checkbox-container input[type="checkbox"]:checked {
    background-color: var(--important-background-color);
    border-color: var(--important-background-color);
    color: var(--important-text-color);
  }

  .checkbox-container label {
    margin: 0;
  }