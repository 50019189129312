.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--main-nav-background-color);
    color: var(--main-content-text-color);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--shadow-color);
    width: 400px;
    }

    .modalOverlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: var(--main-content-text-color);
    }

    .form-group {
        margin-bottom: 15px;
      }
      
      .form-group label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
      }
      
      .form-group input,
      .form-group textarea {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
      }
      
      .form-group textarea {
        resize: vertical;
      }
    
      .form-group input[readonly] {
        cursor: not-allowed;
      }