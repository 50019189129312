[data-theme='banana-split'] {
  --background: #000;
  --text-primary-color: #333;
  --text-secondary-color: #666;
  --page-color: #ffffff;

  --info-background-color: #ffffff;
  --important-background-color: #0051ff;
  --warning-background-color: #ff0;
  --error-background-color: #f00;

  --info-text-color: #000;
  --important-text-color: #fff;
  --warning-text-color: #000;
  --error-text-color: #000000;

  /* Input */
  --input-background-color: #fff;
  --input-text-color: #000;
  --input-border-color: #ccc;
  --input-active-border-color: #007bff;

  /* Shadow Colors */
  --shadow-color: rgba(0, 0, 0, .1);

  /* Logo */
  --logo-top-color: rgb(39, 205, 207);
  --logo-bottom-color: #fabddb;

  /* Login Button */
  --login-button-background-color: #333;
  --login-button-text-color: #ffffff;
  --login-button-hover-background-color: #666;
  --login-button-hover-text-color: #ffffff;
  --login-button-active-background-color: #333;
  --login-button-active-text-color: #ffffff;

  /* Slogan */
  --slogan-color: rgb(238, 238, 132);

  /* Top Nav */
  --top-nav-background-color: #6d5f72;
  --top-nav-text-color: #000;
  --top-nav-button-background-color: #f7f57c;
  --top-nav-button-text-color: #000;
  --top-nav-button-hover-background-color: #3a388d;
  --top-nav-button-hover-text-color: #fff;
  --top-nav-button-active-background-color: #3a388d;
  --top-nav-button-active-text-color: #fff;

    /* Main Navigation */
  --main-nav-background-color: #f0f0f0;
  --main-nav-text-color: #ff0;
  --main-nav-button-background-color: #ff0;
  --main-nav-button-text-color: #000000;
  --main-nav-button-hover-background-color: #e6e600;
  --main-nav-button-hover-text-color: #000000;
  --main-nav-button-active-background-color: #e6e600;
  --main-nav-button-active-text-color: #000000;

  /* Footer */
  --footer-background-color: #333;
  --footer-text-color: #fff;
  --footer-link-color: #fff;
  --footer-link-hover-color: #ff0;

  /* Main Content */
  --main-content-text-color: #000000;
  --main-content-background-color: #ffffff;
  --main-content-card-background-color: #f9f9f9;
  --main-content-button-background-color: #ff0;
  --main-content-button-text-color: #000000;
  --main-content-button-hover-background-color: #e6e600;
  --main-content-button-hover-text-color: #000000;
  --main-content-button-active-background-color: #e6e600;
  --main-content-button-active-text-color: #000000;
}

[data-theme='black-forest'] {
  --background: #121212;
  --text-primary-color: #ffffff;
  --text-secondary-color: #b0bec5;
  --page-color: #1e1e1e;

  --info-background-color: #ffffff;
  --important-background-color: #0051ff;
  --warning-background-color: #ff0;
  --error-background-color: #f00;

  --info-text-color: #000;
  --important-text-color: #fff;
  --warning-text-color: #000;
  --error-text-color: #000000;

  /* Input */
  --input-background-color: #333;
  --input-text-color: #fff;
  --input-border-color: #666;
  --input-active-border-color: #bb86fc;

  /* Shadow Colors */
  --shadow-color: rgba(0, 0, 0, .5);

  /* Logo */
  --logo-top-color: rgb(187, 134, 252);
  --logo-bottom-color: rgb(3, 218, 198);

  /* Login Button */
  --login-button-background-color: #bb86fc;
  --login-button-text-color: #000000;
  --login-button-hover-background-color: #3700b3;
  --login-button-hover-text-color: #ffffff;
  --login-button-active-background-color: #6200ea;
  --login-button-active-text-color: #ffffff;

  /* Slogan */
  --slogan-color: rgb(187, 134, 252);

  /* Top Nav */
  --top-nav-background-color: #1e1e1e;
  --top-nav-text-color: #ffffff;
  --top-nav-button-background-color: #bb86fc;
  --top-nav-button-text-color: #000;
  --top-nav-button-hover-background-color: #3a388d;
  --top-nav-button-hover-text-color: #fff;
  --top-nav-button-active-background-color: #3a388d;
  --top-nav-button-active-text-color: #fff;


    /* Main Navigation */
  --main-nav-background-color: #1e1e1e;
  --main-nav-text-color: #ff0;
  --main-nav-button-background-color: #bb86fc;
  --main-nav-button-text-color: #000000;
  --main-nav-button-hover-background-color: #3a388d;
  --main-nav-button-hover-text-color: #ffffff;
  --main-nav-button-active-background-color: #3a388d;
  --main-nav-button-active-text-color: #ffffff;

  /* Footer */
  --footer-background-color: #1e1e1e;
  --footer-text-color: #ffffff;
  --footer-link-color: #ffffff;
  --footer-link-hover-color: #3a388d;

  /* Main Content */
  --main-content-text-color: #ffffff;
  --main-content-background-color: #121212;
  --main-content-card-background-color: #1e1e1e;
  --main-content-button-background-color: #bb86fc;
  --main-content-button-text-color: #000000;
  --main-content-button-hover-background-color: #3700b3;
  --main-content-button-hover-text-color: #ffffff;
  --main-content-button-active-background-color: #6200ea;
  --main-content-button-active-text-color: #ffffff;
}