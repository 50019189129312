.top-page-navigation {
  display: flex;
  width: 1024px;
  background-color: var(--top-nav-background-color);
  padding: 20px;
  border-radius: 10px;
}

.top-page-navigation-left {
  display: flex;
  justify-content: left;
  gap: 10px;
  flex-direction: row;
}

.top-page-navigation-right {
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-left: auto;
}

.top-page-nav-button {
  background-color: var(--top-nav-button-background-color);
  color: var(--top-nav-button-text-color);
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.top-page-nav-button:hover {
  background-color: var(--top-nav-button-hover-background-color);
  color: var(--top-nav-button-hover-text-color);
}

.top-page-nav-button:active {
  background-color: var(--top-nav-button-active-background-color);
  color: var(--top-nav-button-active-text-color);
}

.top-page-nav-icon {
  font-size: 24px;
}