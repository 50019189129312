.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-content {
  flex: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  background-color: var(--main-content-button-background-color);
  color: var(--main-content-button-text-color);
  border: none;
  border-radius: 30px;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--main-content-button-hover-background-color);
  color: var(--main-content-button-hover-text-color);
}

button:active {
  background-color: var(--main-content-button-active-background-color);
  color: var(--main-content-button-active-text-color);
}
